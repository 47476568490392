import React from 'react';
import { Layout } from '../components/Layout';

const TeamPage = () => {
  return (
    <Layout>
      <section className="page-header">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="my-0 my-lg-5">
                <p className="mb-3 font-weight-500 has-anim fade">Our Awesome</p>
                <h2 className="text-white font-weight-500 has-anim fade anim-delay-1">Powerful Team<span className="text-primary">.</span></h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section bg-white">
        <div className="container">
          <div className="row row-team">
            <div className="col-xl-3 col-lg-4 col-6">
              <div className="team-item has-anim fade">
                <div className="block overflow-hidden position-relative">
                  <img loading="lazy" className="img-fluid" src="assets/images/team/03.jpg" alt="Team Image"/>
                    <div className="social-profile d-flex align-items-end justify-content-center">
                      <ul className="list-unstyled list-inline mb-4 pb-2">
                        <li className="list-inline-item"><a href="#!"><i className="lab la-facebook-f"></i></a></li>
                        <li className="list-inline-item"><a href="#!"><i className="lab la-twitter"></i></a></li>
                        <li className="list-inline-item"><a href="#!"><i className="lab la-linkedin-in"></i></a></li>
                        <li className="list-inline-item"><a href="#!"><i className="lab la-github"></i></a></li>
                      </ul>
                    </div>
                </div>
                <div className="mt-4 text-center">
                  <h4 className="h5 text-dark">Riddo Graham</h4>
                  <p className="h6 font-weight-400 mt-1">Ower &amp; Founder</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-6">
              <div className="team-item has-anim fade anim-delay-1">
                <div className="block overflow-hidden position-relative">
                  <img loading="lazy" className="img-fluid" src="assets/images/team/01.jpg" alt="Team Image"/>
                    <div className="social-profile d-flex align-items-end justify-content-center">
                      <ul className="list-unstyled list-inline mb-4 pb-2">
                        <li className="list-inline-item"><a href="#!"><i className="lab la-facebook-f"></i></a></li>
                        <li className="list-inline-item"><a href="#!"><i className="lab la-twitter"></i></a></li>
                        <li className="list-inline-item"><a href="#!"><i className="lab la-github"></i></a></li>
                      </ul>
                    </div>
                </div>
                <div className="mt-4 text-center">
                  <h4 className="h5 text-dark">Luis Fransisco</h4>
                  <p className="h6 font-weight-400 mt-1">Chief Product Maneger</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-6">
              <div className="team-item has-anim fade anim-delay-2">
                <div className="block overflow-hidden position-relative">
                  <img loading="lazy" className="img-fluid" src="assets/images/team/02.jpg" alt="Team Image"/>
                    <div className="social-profile d-flex align-items-end justify-content-center">
                      <ul className="list-unstyled list-inline mb-4 pb-2">
                        <li className="list-inline-item"><a href="#!"><i className="lab la-facebook-f"></i></a></li>
                        <li className="list-inline-item"><a href="#!"><i className="lab la-twitter"></i></a></li>
                        <li className="list-inline-item"><a href="#!"><i className="lab la-linkedin-in"></i></a></li>
                        <li className="list-inline-item"><a href="#!"><i className="lab la-github"></i></a></li>
                      </ul>
                    </div>
                </div>
                <div className="mt-4 text-center">
                  <h4 className="h5 text-dark">Hena Linton</h4>
                  <p className="h6 font-weight-400 mt-1">Product Designer</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-6">
              <div className="team-item has-anim fade anim-delay-3">
                <div className="block overflow-hidden position-relative">
                  <img loading="lazy" className="img-fluid" src="assets/images/team/04.jpg" alt="Team Image"/>
                    <div className="social-profile d-flex align-items-end justify-content-center">
                      <ul className="list-unstyled list-inline mb-4 pb-2">
                        <li className="list-inline-item"><a href="#!"><i className="lab la-facebook-f"></i></a></li>
                        <li className="list-inline-item"><a href="#!"><i className="lab la-twitter"></i></a></li>
                        <li className="list-inline-item"><a href="#!"><i className="lab la-linkedin-in"></i></a></li>
                        <li className="list-inline-item"><a href="#!"><i className="lab la-github"></i></a></li>
                      </ul>
                    </div>
                </div>
                <div className="mt-4 text-center">
                  <h4 className="h5 text-dark">Joshuwa Delgado</h4>
                  <p className="h6 font-weight-400 mt-1">Web Developer</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-6">
              <div className="team-item has-anim fade">
                <div className="block overflow-hidden position-relative">
                  <img loading="lazy" className="img-fluid" src="assets/images/team/05.jpg" alt="Team Image"/>
                    <div className="social-profile d-flex align-items-end justify-content-center">
                      <ul className="list-unstyled list-inline mb-4 pb-2">
                        <li className="list-inline-item"><a href="#!"><i className="lab la-facebook-f"></i></a></li>
                        <li className="list-inline-item"><a href="#!"><i className="lab la-twitter"></i></a></li>
                        <li className="list-inline-item"><a href="#!"><i className="lab la-github"></i></a></li>
                      </ul>
                    </div>
                </div>
                <div className="mt-4 text-center">
                  <h4 className="h5 text-dark">Rubaik Jakson</h4>
                  <p className="h6 font-weight-400 mt-1">Markating Head</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-6">
              <div className="team-item has-anim fade anim-delay-2">
                <div className="block overflow-hidden position-relative">
                  <img loading="lazy" className="img-fluid" src="assets/images/team/06.jpg" alt="Team Image"/>
                    <div className="social-profile d-flex align-items-end justify-content-center">
                      <ul className="list-unstyled list-inline mb-4 pb-2">
                        <li className="list-inline-item"><a href="#!"><i className="lab la-facebook-f"></i></a></li>
                        <li className="list-inline-item"><a href="#!"><i className="lab la-github"></i></a></li>
                      </ul>
                    </div>
                </div>
                <div className="mt-4 text-center">
                  <h4 className="h5 text-dark">Alentrona Shimla</h4>
                  <p className="h6 font-weight-400 mt-1">UI Artist</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-6">
              <div className="team-item has-anim fade anim-delay-3">
                <div className="block overflow-hidden position-relative">
                  <img loading="lazy" className="img-fluid" src="assets/images/team/07.jpg" alt="Team Image"/>
                    <div className="social-profile d-flex align-items-end justify-content-center">
                      <ul className="list-unstyled list-inline mb-4 pb-2">
                        <li className="list-inline-item"><a href="#!"><i className="lab la-facebook-f"></i></a></li>
                        <li className="list-inline-item"><a href="#!"><i className="lab la-github"></i></a></li>
                      </ul>
                    </div>
                </div>
                <div className="mt-4 text-center">
                  <h4 className="h5 text-dark">Jason Moyer</h4>
                  <p className="h6 font-weight-400 mt-1">Business Consultant</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-6">
              <div className="team-item has-anim fade anim-delay-1">
                <div className="block overflow-hidden position-relative">
                  <img loading="lazy" className="img-fluid" src="assets/images/team/08.jpg" alt="Team Image"/>
                    <div className="social-profile d-flex align-items-end justify-content-center">
                      <ul className="list-unstyled list-inline mb-4 pb-2">
                        <li className="list-inline-item"><a href="#!"><i className="lab la-facebook-f"></i></a></li>
                        <li className="list-inline-item"><a href="#!"><i className="lab la-github"></i></a></li>
                      </ul>
                    </div>
                </div>
                <div className="mt-4 text-center">
                  <h4 className="h5 text-dark">Luke Jones</h4>
                  <p className="h6 font-weight-400 mt-1">Graphic Designer</p>
                </div>
              </div>
            </div>

            <div className="col-12 mt-5 pt-4 text-center has-anim fade">
              <a className="btn btn-black" href="team.html#open-positions" data-text="See Open Positions"><span>See Open Positions</span></a>
            </div>
          </div>
        </div>
      </section>

      <section id="open-positions" className="section" style={{
        borderBottom: '1px solid #151829'
      }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center has-anim fade">
                <p className="mb-2">Apply On Our</p>
                <h2 className="title text-white">Open Positions</h2>
              </div>

              <div className="row justify-content-center pt-3 has-anim fade">
                <div className="col-md-4 mb-4 mb-md-0">
                  <h3 className="text-white font-weight-500">Customer Support</h3>
                </div>
                <div className="col-lg-7 col-md-8">
                  <ul className="open-positions-list pl-0 pl-md-5">
                    <li>
                      <h4><a href="#!" className="text-light font-weight-500 d-block mb-2" target="_blank">Customer Support Specialist - Billing, (NZ/Australia)</a></h4>
                      <span>New Zealand or Australia Remote</span>
                    </li>
                    <li>
                      <h4><a href="#!" className="text-light font-weight-500 d-block mb-2" target="_blank">Manager, Customer Support (UK/Ireland)</a></h4>
                      <span>UK/Ireland Remote</span>
                    </li>
                    <li>
                      <h4><a href="#!" className="text-light font-weight-500 d-block mb-2" target="_blank">Technical Customer Support Specialist, (NZ/Australia)</a></h4>
                      <span>New Zealand or Australia Remote</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="row justify-content-center pt-3 mt-5 has-anim fade">
                <div className="col-md-4 mb-4 mb-md-0">
                  <h3 className="text-white font-weight-500">Marketing</h3>
                </div>
                <div className="col-lg-7 col-md-8">
                  <ul className="open-positions-list pl-0 pl-md-5">
                    <li>
                      <h4><a href="#!" className="text-light font-weight-500 d-block mb-2" target="_blank">
                        Product Marketing Lead</a></h4>
                      <span>San Francisco HQ or US Remote</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="row justify-content-center pt-3 mt-5 has-anim fade">
                <div className="col-md-4 mb-4 mb-md-0">
                  <h3 className="text-white font-weight-500">Design</h3>
                </div>
                <div className="col-lg-7 col-md-8">
                  <ul className="open-positions-list pl-0 pl-md-5">
                    <li>
                      <h4><a href="#!" className="text-light font-weight-500 d-block mb-2" target="_blank">Senior Product Designer, Growth</a></h4>
                      <span>Remote</span>
                    </li>
                    <li>
                      <h4><a href="#!" className="text-light font-weight-500 d-block mb-2" target="_blank">Senior Product Designer, Marketplace</a></h4>
                      <span>US Remote</span>
                    </li>
                    <li>
                      <h4><a href="#!" className="text-light font-weight-500 d-block mb-2" target="_blank">Senior Product Design Manager, Design Systems</a></h4>
                      <span>US remote</span>
                    </li>
                    <li>
                      <h4><a href="#!" className="text-light font-weight-500 d-block mb-2" target="_blank">Senior UI Designer, Design Systems</a></h4>
                      <span>US Remote</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default TeamPage;